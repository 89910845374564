import React, { useEffect, useState } from 'react';
import '../CSS/DemoForm.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const DemoForm = ()=>{
    const [cdetail, setCDetail] = useState({
        fname: '',
        lname: '',
        cname: '',
        cwebsite: '',
        bemail: '',
        country: '',
        phone: ''
    })

    const [service, setService] = useState({
        archive: false,
        email: false,
        social_media: false,
        website: false,
        text_messages: false,
        collboration_tools: false,
        audio: false,
        case_management: false,
        approval_management: false
    })

    

    const {fname, lname, cname, cwebsite, bemail, country, phone} = cdetail;

    useEffect(()=>{
        if(fname !== '')
            document.getElementById("fname-label").style.cssText = "top: -12px;font-size: 14px;";
        else
            document.getElementById("fname-label").style.cssText = "top: 10px;";
        if(lname !== '')
            document.getElementById("lname-label").style.cssText = "top: -12px;font-size: 14px;"
        else
            document.getElementById("lname-label").style.cssText = "top: 10px;";
        if(cname !== '')
            document.getElementById('cname-label').style.cssText = 'top: -12px;font-size: 14px;';
        else
            document.getElementById('cname-label').style.cssText = 'top: 10px;';
        if(cwebsite !== '')
            document.getElementById('cwebsite-label').style.cssText = 'top: -12px;font-size: 14px;';
        else
            document.getElementById('cwebsite-label').style.cssText = 'top: 10px';
        if(bemail !== '')
            document.getElementById('bemail-label').style.cssText = 'top: -12px;font-size: 14px;';
        else
            document.getElementById('bemail-label').style.cssText = 'top: 10px';
        if(country !== '')
            document.getElementById('country-label').style.cssText = 'top: -12px;font-size: 14px;';
        else
            document.getElementById('country-label').style.cssText = 'top: 10px';
        if(phone !== '')
            document.getElementById('phone-label').style.cssText = 'top: -12px;font-size: 14px;';
        else
            document.getElementById('phone-label').style.cssText = 'top: 10px';
    }, [fname, lname, cname, cwebsite, bemail, country, phone]);

    const {archive, email, social_media, website, text_messages, collboration_tools, audio, case_management, approval_management} = service;

    const success_demo = `
    <div className="success-demo" id="success-demo">
    <div className="box">
        <img src="https://storage.googleapis.com/complaincebrain-public/compliance-brain-Website/Success.png" alt="check" />
    </div>
    <p style={{paddingTop: '10px'}}>Thank you for filling the form. We will get back to you soon.</p>
</div>`;

    const Send = (e)=>{
        e.preventDefault();

        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(fname === '' || lname === '' || cname === '' || bemail === '' || country === '' || ( archive === false && email === false && social_media === false && website === false && text_messages === false && collboration_tools === false && audio === false && case_management === false && approval_management === false)){
            window.alert("Please fill all required fields");
        }
        else{
            if(re.test(bemail)){
                if(phone.length < 10 && phone.length>0)
                    window.alert("Please Enter Valid Phone Number");
                else{
                    document.getElementById("schedule-demo-container").children[2].outerHTML = success_demo;
                }
            }
            else{
                window.alert("Please Enter Valid Email Address");
            }
        }
    }

    return (
        <>
            <div className="demoform container-fluid" id="schedule-demo">
                <div className="container-lg" id="schedule-demo-container">
                    <h1>Schedule a Demo</h1>
                    <div className="line"></div>
                    
                    <form method="POST" className="form" id="schedule-form">
                        <div className="part1 form-part">
                            <div>
                                <input type="text" id="fname" value={cdetail.fname} onChange={(e)=>setCDetail({...cdetail, fname:e.target.value})} />
                                <label id="fname-label" for="fname">First Name*</label>
                            </div>
                            <div>
                                <input type="text" id="lname" value={cdetail.lname} onChange={(e)=>setCDetail({...cdetail, lname: e.target.value})} />
                                <label id="lname-label" for="lname">Last Name*</label>
                            </div>
                            <div>
                                <input type="text" id="cname" value={cdetail.cname} onChange={(e)=>setCDetail({...cdetail, cname: e.target.value})} />
                                <label id="cname-label" for="cname">Company Name*</label>
                            </div>
                            <div>
                                <input type="url" id="cwebsite" value={cdetail.cwebsite} onChange={(e)=>setCDetail({...cdetail, cwebsite: e.target.value})} />
                                <label id="cwebsite-label" for="cwebsite">Company Website</label>
                            </div>
                            <div>
                                <input type="email" id="bemail" value={cdetail.bemail} onChange={(e)=>setCDetail({...cdetail, bemail: e.target.value})} />
                                <label id="bemail-label" for="bemail">Business Email*</label>
                            </div>
                            <div>
                                <input type="text" id="country" value={cdetail.country} onChange={(e)=>setCDetail({...cdetail, country: e.target.value})} />
                                <label id="country-label" for="country">Country*</label>
                            </div>
                            <div>
                                <input type="tel" id="phone" value={cdetail.phone} onChange={(e)=>setCDetail({...cdetail, phone: e.target.value})} maxLength="10" minLength="10" />
                                <label id="phone-label" for="phone">Phone Number</label>
                            </div>
                        </div>
                        <div className="part2 form-part">
                            <h3>Interested in:*</h3>
                            <div className="archive">
                                <input type="checkbox" onClick={(e)=>{
                                    if(e.target.checked === true)
                                        setService({...service, archive: true});
                                    else
                                        setService({...service, archive: false})
                                }}
                                name="Archive" id="archive"/>
                                <label for="archive"></label><span>Archive</span>
                            </div>
                            <div className="row1 form-part">
                                <div>
                                    <input type="checkbox" onClick={(e)=>{
                                        if(e.target.checked === true)
                                            setService({...service, email: true});
                                        else
                                            setService({...service, email: false});
                                    }} name="email" id="email" />
                                    <label for="email"></label><span>Email</span>
                                </div>
                                <div>
                                    <input type="checkbox" onClick={(e)=>{
                                        if(e.target.checked === true)
                                            setService({...service, social_media: true});
                                        else
                                        setService({...service, social_media: false});
                                    }} name="social-media" id="social-media" />
                                    <label for="social-media"></label><span>Social Media</span>
                                </div>
                                <div>
                                    <input type="checkbox" onClick={(e)=>{
                                        if(e.target.checked === true)
                                            setService({...service, website: true});
                                        else
                                        setService({...service, website: false});
                                    }} name="website" id="website" />
                                    <label for="website"></label><span>Website</span>
                                </div>
                            </div>
                            <div className="row2 form-part">
                                <div>
                                    <input type="checkbox" onClick={(e)=>{
                                        if(e.target.checked === true)
                                            setService({...service, text_messages: true});
                                        else
                                            setService({...service, text_messages: false});
                                    }} name="text-messages" id="text-messages" />
                                    <label for="text-messages"></label><span>Text Messages</span>
                                </div>
                                <div>
                                    <input type="checkbox" onClick={(e)=>{
                                        if(e.target.checked === true)
                                            setService({...service, collboration_tools: true});
                                        else
                                            setService({...service, collboration_tools: false});
                                    }} name="collaboration-tools" id="collaboration-tools" />
                                    <label for="collaboration-tools"></label><span>Collaboration Tools</span>
                                </div>
                                <div>
                                    <input type="checkbox" onClick={(e)=>{
                                        if(e.target.checked === true)
                                            setService({...service, audio: true});
                                        else
                                            setService({...service, audio: false});
                                    }} name="audio" id="audio" />
                                    <label for="audio"></label><span>Audio</span>
                                </div>
                            </div>
                            <div className="case form-part">
                                <input type="checkbox" onClick={(e)=>{
                                    if(e.target.checked === true)
                                        setService({...service, case_management: true});
                                    else
                                        setService({...service, case_management: false});
                                }} name="case-management" id="case-management" />
                                <label for="case-management"></label><span>Case Management</span>
                            </div>
                            <div className="approval form-part">
                                <input type="checkbox" onClick={(e)=>{
                                    if(e.target.checked === true)
                                        setService({...service, approval_management: true});
                                    else
                                        setService({...service, approval_management: false});
                                }} name="approval-management" id="approval-management" />
                                <label for="approval-management"></label><span>Approval Management</span>
                            </div>
                        </div>
                        <div className="part3 form-part">
                            <button type="submit" onClick={Send}>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
};

export default DemoForm;