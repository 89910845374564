import React from 'react';
import './App.css';
import {Switch, Route} from 'react-router-dom';
import Home from './Components/Home';

const App = ()=>{
    return (
        <>
            <Switch>
                <Route path="/" exact={true} component={Home}><Home /></Route>
                <Route path="/resources" exact={true}></Route>
                <Route path="/contact" exact={true}></Route>
            </Switch>
        </>
    )
};

export default App;